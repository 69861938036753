@import "defaults";

.default__bg-layer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  .header {
    padding-top: 10px;
    margin: 0 20px 67px;
    height: unset;
  }

  .text__content {
    max-width: 1200px;
    margin: 20px 15px;
  }

  .footer {
    padding-top: 0;
    min-height: unset;
    //background: none;
  }
}
