@import "defaults";

@include desktop {
  .commissions__wrap--more {

    .header {
      margin-bottom: 50px;
    }

    .commissions {
      max-width: 1240px;
      max-height: 692px;
      flex-grow: 1;

      &__header-cont {
        display: flex;
        justify-content: space-between;
        max-width: 1140px;
        margin: 0 auto;

        h2 {
          font-size: 56px;
          line-height: 65px;
          font-weight: 900;
        }

        .back-btn {
          display: block;
          width: 125px;
          padding: 0;
          border: 1px solid $opaqueGray2;
          color: $defaultWhite;

          img {
            margin-right: 5px;
          }

          &:hover {
            border: none;
            padding: 0;
          }
        }
      }

      &__header {
        max-width: 75%;

        h2 {
          text-transform: unset;
        }
      }

      .slider {
        &__item {
          padding-top: 45px;
        }

        & .slick-arrow {
          top: 206px;
        }

        &__item-content {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          max-width: 1140px;
          padding: 43px 90px;

          &__img-plaque {
            display: none;
          }

          &__img {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            padding-right: 90px;
          }

          h3 {
            display: none;
          }

          ul {
            width: 55%;
          }
        }
      }
    }

    .footer {
      padding-top: 0;
    }
  }
}