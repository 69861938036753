@mixin projectIcons ($fillColor: $sidebarIconsColor) {
  vertical-align: middle;
  path {
    fill: $fillColor;
  }
}

.icon-custom {
  @include projectIcons();

  &-secondary {
    vertical-align: middle;
  }
}
