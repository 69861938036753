.registration {
  .recaptcha {
    div {
      margin: 0 auto;
    }
  }
  .ant-input {
    min-width: 300px;
  }
}
