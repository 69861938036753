@import "defaults";

@include tablet {
  .commissions__wrap {
    min-height: 100vh;
    padding: 55px 0 0 0;
    .header {
      margin-bottom: 82px;
    }

    .commissions {
      flex-grow: 1;
      padding-top: 0;

      .slider {
        &__item-content {
          padding-top: 135px;
        }
      }
    }

    .footer {
      padding-top: 20px;
      width: 100%;
      //background: none;
    }
  }
}

@include desktop {
  .commissions__wrap {
    margin: 0 auto;

    .commissions {
      max-width: 1140px;
      padding-left: 0;
      padding-right: 0;
    }

    .footer {
    }
  }
}
