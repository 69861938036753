.landing-pages {
  @import "defaults";
  @import "landing";
  @import "landing--media";
  @import "commissions";
  @import "commissions--media";
  @import "commissions-more";
  @import "commissions-more--media";
  @import "faq";
  @import "faq--media";
  @import "conditions";
  @import "default";
  @import "default--media";
  @import "testimonials";
  @import "news";
  @import "tournaments";

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
  }

  body.modal-opened {
    overflow: hidden;
  }

  h1 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-size: 35px;
    font-weight: 900;
    line-height: 41px;
    color: $titleH2Color;
  }

  h2 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 30px;
    font-weight: 900;
    line-height: 35px;
    color: $titleH2Color;
  }

  h3 {
    margin: 0;
    font-family: $titleFont, sans-serif;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    line-height: 28px;
    color: $titleBlack;
  }

  p {
    font-family: $mainSansSerif;
    margin: 0;
  }

  form {
    input,
    textarea {
      margin: 20px auto 0;
      padding: 14px 24px;
      width: 100%;
      background: $defaultWhite;
      height: 45px;
      border: none;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      color: $titleBlack;
      font-size: 14px;
      line-height: 16px;
      box-shadow: 0 15px 30px $slideBoxShadow;
      resize: none;

      &::placeholder {
        display: inline;
        vertical-align: top;
      }

      &:focus,
      :active {
        outline: none;
        padding: 12px 22px;
        border: 2px solid $borderBlue;
      }
    }
  }

  .btn {
    @include resetButton;
    display: block;
    // padding: 15.5px 14px 14.5px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;

    a {
      &:focus {
        outline: none;
      }
    }

    &__default {
      width: 180px;
      height: 45px;
      margin: 0 auto;
      color: $defaultWhite;
      text-align: center;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      background: $mainButtonGradient;
      box-shadow: 0 13px 30px $btnBoxShadowTint;

      a {
        display: block;
        padding: 15.5px 14px 14.5px;
        width: 100%;
        height: 100%;
        color: inherit;
        text-decoration: none;
      }

      &:hover {
        background: $mainButtonGradientActive;
      }
    }

    &__hollow {
      width: 124px;
      height: 45px;
      //padding: 15.5px 14px 14.5px;
      color: $mainOrange;
      border: 1px solid $opaqueBlack;
      border-radius: 30px;

      &:hover {
        color: $defaultWhite;
        background: $hollowBtnHoverGradient;
        box-shadow: 0 13px 30px $btnBoxShadowTint;
        //padding: 16.5px 15px 15.5px;
        border: none;
      }

      a {
        display: block;
        width: 100%;
        padding: 15px 28px;
        text-decoration: none;
        font-family: $mainSansSerif;
        color: $mainOrange;

        &:hover {
          padding: 16px 29px;
          color: $defaultWhite;
        }
      }
    }

    &__scroll {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      background: $defaultWhite;
      box-shadow: 0 15px 50px $slideBoxShadow;
      transition: linear 0.4s;

      &:hover {
        box-shadow: 0 15px 50px $btnBoxShadowTint;
        transition: linear 0.4s;
      }
    }
  }

  .close-modal-x {
    position: relative;
    color: $defaultWhite;
    min-width: 15px;
    min-height: 15px;
    font-size: 12px;
    line-height: 14px;
    align-self: flex-end;
    padding-right: 23px;
    text-transform: uppercase;
    cursor: pointer;

    @include large-mobile {
      position: absolute;
      padding-right: 28px;
      top: 30px;
      right: 30px;
      font-size: 18px;
    }

    &:before,
    &:after {
      bottom: 7px;
      right: -4px;
      position: absolute;
      display: block;
      content: "";
      width: 20px;
      height: 0;
      border: 1px solid $defaultWhite;
      @include large-mobile {
        width: 25px;
        bottom: 7px;
      }
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .menu-modal {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: fixed;
    top: 0;
    left: 0;
    padding: 25px 20px;
    overflow: auto;
    background-color: $secondaryMain;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    @include large-mobile {
      justify-content: center;
      align-items: center;
    }

    &__menu {
      padding: 0;
      margin: 52px 0;
      list-style: none;

      .menu-item {
        margin-bottom: 12px;
      }

      .language-flag {
        height: 75px;
      }

      .menu-item a {
        font-family: $titleFont;
        text-decoration: none;
        font-size: 50px;
        line-height: 58px;
        font-weight: 800;
        color: $defaultWhite;
      }
    }

    .login-area {
      display: flex;
      flex-direction: column;
      width: 100%;
      @include large-mobile {
        max-width: 290px;
      }

      .btn {
        &__content-wrap {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            margin: 0 0 3px 8px;
          }
        }
      }

      .btn__hollow {
        width: 100%;
        margin-bottom: 10px;
        border: 1px solid $opaqueGray2;
        color: $defaultWhite;
      }

      .btn__default {
        width: 100%;
      }
    }
  }

  .menu-modal.opened {
    display: flex;
  }

  .modal {
    display: none;
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    z-index: 9999;
    padding: 85px 20px 150px;
    background: $modalBackground;
    width: 100%;
    height: 100%;

    .close-modal-x {
      position: absolute;
      top: 18px;
      right: 18px;
      opacity: 0.3;

      &:before,
      &:after {
        border: 1px solid $titleBlack;
      }
    }

    .reset-pass-form.modal-form {
      @include desktop {
        .btn__default {
          width: 48.5%;
        }
      }
    }

    .modal-form,
    .registration-complete {
      position: relative;
      display: none;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      padding: 20px 30px 25px;
      max-width: 375px;
      background-color: $defaultWhite;
      border-radius: 15px;

      .captcha-box {
        display: none;
      }

      h3 {
        margin-bottom: 30px;
      }

      &__input-cont {
        width: 100%;
        margin-bottom: 30px;
      }

      span ~ .modal-form__input-cont {
        margin-top: 15px;
      }

      input {
        margin: 0;
        box-shadow: none;
        border: 1px solid $opaqueGray3;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        &:focus {
          padding: 13px 23px;
          border: 2px solid $borderBlue;
        }
      }

      span {
        color: $opaqueBlack2;
      }

      .btn {
        margin: 0 0 10px;
        width: 100%;
      }

      .checkbox-cont {
        display: block;
        position: relative;
        margin: 10px 0;
        cursor: pointer;
        font-size: 14px;
        line-height: 16px;
        color: $opaqueBlack2;
        padding-left: 40px;
        user-select: none;

        &:focus {
          outline: none;
        }

        &:last-child {
          margin-bottom: 0;
        }

        &:hover input ~ .checkmark {
          background-color: $opaqueGray2;
        }

        input:checked ~ .checkmark {
          background-color: $dotBlue;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 10px;
          height: 20px;
          width: 20px;
          background-color: #fff;
          border: 1px solid $opaqueGray3;
          border-radius: 2px;

          @media (min-width: 382px) {
            top: -3px;
          }

          &:after {
            content: "";
            position: absolute;
            display: none;
            left: 6px;
            top: 2px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }

        input:checked ~ .checkmark:after {
          display: block;
        }
      }

      &__footer {
        margin-top: 20px;
      }

      .clickable-text {
        display: block;
        margin-top: 10px;
        text-align: center;
        font-size: 14px;
        line-height: 16px;
        color: $dotBlue;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      @include desktop {
        min-width: 640px;

        .captcha-box {
          display: block;
          height: 66px;
          background-color: $opaqueGray3;
          border-radius: 15px;
          text-align: center;
        }

        &__input-cont {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;

          input,
          div {
            width: 48.5%;
            margin: 0 auto;
          }
        }
      }
    }

    .registration-complete {
      h3 {
        margin: 0;
      }

      &__img-cont {
        margin: 30px auto;
      }

      span {
        text-align: center;

        &:not(:last-child) {
          margin-bottom: 10px;
        }

        .registration-complete__username {
          color: $titleBlack;
        }
      }
    }

    .modal-form.opened,
    .registration-complete.opened {
      display: flex;
    }
  }

  .modal.opened {
    display: block;
  }
}

.languages-overlay {
  @import "defaults";
  .ant-menu {
    background-color: $dropdownMenuShadow;
    border-radius: 20px;
    margin-top: 10px;
  }
  .ant-menu-vertical {
    border-right: none;
  }
}
