// MIXINS
@mixin resetButton {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}


@mixin large-mobile {
  @media (min-width: 480px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}

// FONTS
$mainSansSerif: Play;
$titleFont: Play;
$subtitleFont: Play;
$focoReg: Play;

// COLORS
$defaultWhite: #fff;
$mainOrange: #ED5457;
$titleBlack: #0E0303;
$titleH2Color: #3C2691;
$backgroundGray: #edf4ff;
$dotBlue: #614AD3;
$opaqueBlack: rgba(0, 0, 0, 0.2);
$opaqueBlack2: rgba(0, 0, 0, 0.5);
$opaqueGray: rgba(240, 240, 240, 0.4);
$opaqueGray2: rgba(213, 213, 213, 0.4);
$opaqueGray3: rgba(130, 149, 166, 0.2);
$gray: #F7F7F7;
$opaqueSection: rgba(100, 179, 68, 0.05);
$borderBlue: rgba(0, 102, 255, 0.6);
$modalBackground: rgba(0, 102, 255, 0.8);
$slideBoxShadow: rgba(66, 67, 145, 0.15);
$backgroundLightBlue: rgba(0, 102, 255, 0.03);
$btnBoxShadowTint: rgba(66, 67, 145, 0.3);
$mainButtonGradient: #FD0059;
$mainButtonGradientActive: #614AD3;
$hollowBtnHoverGradient: linear-gradient(90deg,#fd0059 100%,#fd0024 0);
$underlineGradient: linear-gradient(90deg,#fd0059 100%,#fd0024 0);
$dropdownMenuShadow: rgba(204, 215, 234, 0.8);