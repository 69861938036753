.commissions__wrap--more {
  .commissions {

    &__header-cont {
      .back-btn {
        display: none;
      }
    }

    .slider {
      &__item {
        height: unset;
        padding-bottom: 50px;
      }

      &__item-content {
        padding: 105px 20px 30px;
        height: unset;
        border-radius: 30px;

        &__img {
          display: none;
        }

        h3 {
          display: flex;
          justify-content: center;
          align-items: center;
          min-height: 110px;
          padding: 0 30px;
        }

        ul {
          margin: 0;
          padding: 0;
          font-family: $mainSansSerif;
          font-size: 16px;
          line-height: 19px;
          font-weight: normal;
          text-align: left;
          list-style: none;

          li {
            padding: 0 0 0 40px;
            background: url("../../images/landings/commissions_tab/list-style.svg") no-repeat left top;

            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }

        .btn {
          display: none;
        }
      }
    }
  }
}