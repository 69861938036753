@import "vars";
@import "../assets/styles/main";
@import "fonts";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "landing/index";

body {
  background: $main_bg;
  overflow-x: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Play", "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}
