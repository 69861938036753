.program-expand-content {
  &__item {
    padding: 16px;
    border-bottom: 1px solid #f0f0f0;
  }
}
.affiliate-programs-table {
  .ant-table-tbody > tr.ant-table-expanded-row > td.ant-table-cell{
    padding: 0;
    border: none;
  }

}

.affiliate-brand-item {
  margin-bottom: 20px;
}

.affiliate-brand-info {
  display: flex;
  align-items: center;

  
  @include media('<laptop-small') {
    display: block;
  }
  &__controls {
    margin-left: auto;

    @include media('<old') {
      display: block;

    }

    .anticon {
      margin-right: 10px;
    }

    .ant-btn {
      padding: 0 15px;
      font-size: 12px;

      @include media('<old'){
        width: 100%;
      }
    }
    .ant-space-item {

      @include media('<old') {
        margin-right: 0 !important;
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  &__logo {
    width: 200px;
    height: 40px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media('<laptop-small') {
      margin: 20px 0;
      justify-content: flex-start;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .ant-typography {
    margin-bottom: 10px;
  }
}

.affiliate-brand-status {
  color: $greyMain;
  font-size: 12px;
  line-height: 24px;

  &__mark {
    display: inline-block;
    vertical-align: middle;
    background-color: $greyMain;
    color: $alwaysBlack;
    padding: 0 10px;
    border-radius: 3px;
    font-size: 14px;
    margin-left: 5px;
  }

  &--active {
    .affiliate-brand-status {
      &__mark {
        background-color: $defaultActiveMain;
        color: $alwaysWhite;
      }
    }
  }

  &--special {
    .affiliate-brand-status {
      &__mark {
        background-color: $radioBtnActive;
        color: $alwaysWhite;
      }
    }
  }
}
.brand-edit-form {
  &__logo {
    color: #8295a6;
    font-size: 14px;
    img {
      max-width: 200px;
      object-fit: contain;
    }
  }
}

.upload-button {
  display: block;
  margin-top: 30px;
  position: relative;

  input[type='file']{
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 0;
    visibility: hidden;
  }
  button{
    pointer-events: none;
    cursor: pointer;
  }
  .anticon {
    margin-right: 10px;
  }
}

.program-user-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
  margin: 0 -10px;
  li{
    padding: 0 10px;
    padding-bottom: 10px;
  }
}
.affiliate-landing-card {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 10px;
  transition: box-shadow .2s ease, border-color .2s ease;

  &:hover {
    box-shadow: 0 0 10px #d9d9d9;
    border-color: rgba(0,0,0, .3);
  }

  &__image {
    height: 135px;
    position: relative;
    border-bottom: 1px solid #d9d9d9;
    pointer-events: none;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  &__content {
    padding: 10px 15px;
    min-height: 215px;
    position: relative;
    padding-bottom: 45px;

  }
  &__content-wrap {
    pointer-events: none;
    cursor: default;
    user-select: none;
  }
  &__controls {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px 15px;
    border-top: 1px solid #d9d9d9;

    .ant-space-item {
      flex: 1;
    }
    .ant-btn-sm {
      font-size: 12px;
      line-height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 5px 8px;
      height: auto;
      width: 100%;
    }
  }
  &__url {
    word-break: break-all;
    font-size: 12px;
    line-height: 1.2;
  }
  &__title {
    &.ant-typography {
      font-size: 14px;
      line-height: 1.2;
    }
  }
}

.landing-page-url-description {
  font-size: 12px;
  margin-bottom: 14px;
  .ant-space {
    align-items: flex-start;
    margin-bottom: 0;
    width: 100%;
  }
  .ant-form-item{
    margin-bottom: 0;
  }
  .ant-space-item {
    &:first-child {
      flex: 1;
    }
  }
  .ant-btn {
    height: 45px;
    margin-top: 30px;
  }
}
.affiliate-landing-page-form,
.affiliate-program-form {
  .ant-form-item-label > label {
    padding-left: 10px;
    height: auto;
  }
}

.landing-page-preview {
  p{
    color: $greyMain;
    line-height: 22px;
    margin-bottom: 8px;
  }

  img {
    width: 235px;
    height: auto;
    object-fit: contain;
    border-radius: 8px;
  }
}

.landings-dnd {
  margin: 0 -10px;
  touch-action: none;

  &__item {
    padding: 0 10px;
    padding-bottom: 10px;
    will-change: transform, opacity;
  }
}
.affiliate-program-options {
  .ant-card-head {
    border-bottom: 1px solid #d9d9d9;
  }
  .ant-card-head-title {
    padding: 10px 0;
  }
}
.input-description {
  font-size: 10px;
  color: #b4b4b4;
  display: block;
  padding-left: 11px;
  margin-top: -10px;
}
