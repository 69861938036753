@import "defaults";

.commissions__wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding: 10px 0 0;

  .header {
    margin-bottom: 67px;
  }

  .commissions {
    width: 100%;
    max-width: 455px;
    margin-bottom: 0;

    h2 {
      padding-left: 20px;
      font-size: 35px;
      line-height: 41px;
      text-align: left;
      letter-spacing: unset;
    }

    h3 {
      font-weight: bold;
    }

    .slider {
      position: relative;
      &__item {
        width: unset;
        min-width: 280px;
        padding-top: 130px;
      }
    }
  }

  .footer {
    min-height: unset;
    padding-top: 25px;
  }
}
